@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply dark:bg-black-special;
  }

  h1 {
    @apply mb-6 text-3xl text-gray-600 hover:text-gray-400 dark:text-gray-special dark:hover:text-gray-200;
  }

  h2 {
    @apply text-2xl hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200;
  }

  p {
    @apply mb-4 text-gray-600 dark:text-gray-400;
  }

  a {
    @apply border-gray-500 text-indigo-special dark:text-dark-indigo-special;
  }
  a:hover {
    @apply underline text-indigo-special-hover dark:text-dark-indigo-special-hover;
  }

  article {
    @apply dark:bg-black-special;
  }

  div {
    @apply dark:bg-black-special;
  }
}

.prose h2,
.prose h3,
.prose h4,
.prose strong,
.prose th,
.prose td {
  @apply text-gray-600 dark:text-gray-400;
}

.prose li {
  @apply text-gray-600 dark:text-gray-400;
}

.prose pre {
  @apply bg-gray-900 border border-gray-700;
}

.prose hr {
  @apply border dark:border-gray-600;
}

.prose {
  @apply table-auto max-w-none;
}

.prose code {
  @apply text-gray-400 dark:text-gray-300 px-1 py-0.5 rounded-md;
}

code[class*='language-'],
pre[class*='language-'] {
  @apply mt-0;
}
